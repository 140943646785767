<template>
 <div>
   <el-card style="display: flex">
     <span>
       <label style="margin-right: 20px">TrackingNumber: {{e2eTrackDetail.trackingNumber}}</label>
       <label style="margin-right: 20px"> Container Id: {{e2eTrackDetail.ctnrId}}</label>
       <label> MBL: {{e2eTrackDetail.mbl}}</label>
     </span>
     <el-divider />
     <el-steps :active="active" align-center finish-status="success">
       <el-step :title="$t('msg.track.discharge')" :description="e2eTrackDetail.discharge" />
       <el-step :title="$t('msg.track.fullGateOut')" :description="e2eTrackDetail.fullGateOut" />
       <el-step :title="$t('msg.track.dWHArrival')" :description="e2eTrackDetail.dWHArrival" />
       <el-step :title="$t('msg.track.swER')" :description="e2eTrackDetail.swER" />
     </el-steps>
  </el-card>
   <el-card >
     <el-descriptions :title="$t('msg.track.basicInfo')" column="6">
       <el-descriptions-item :label="$t('msg.track.eta')">{{e2eTrackDetail.eta}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.track.swFinalArrival')" >{{e2eTrackDetail.swFinalArrival}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.track.swDischarge')">{{e2eTrackDetail.swDischarge}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.track.fullGateOut')">{{e2eTrackDetail.fullGateOut}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.track.discharge')">
         <el-tag size="small">{{e2eTrackDetail.termLFD}}</el-tag>
       </el-descriptions-item>
       <el-descriptions-item :label="$t('msg.track.emptyReturn')">{{e2eTrackDetail.emptyReturn}}</el-descriptions-item>
     </el-descriptions>
     <el-divider />
     <el-descriptions :title="$t('msg.track.trackDetails')" column="6">
       <el-descriptions-item :label="$t('msg.track.client')">{{e2eTrackDetail.client}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.track.pod')" >{{e2eTrackDetail.pod}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.track.sslCarrier')">{{e2eTrackDetail.sslCarrier}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.track.vessel')">{{e2eTrackDetail.vessel}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.track.voyage')">{{e2eTrackDetail.voyage}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.track.ctnrSize')">{{e2eTrackDetail.ctnrSize}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.track.ctnrCbm')">{{e2eTrackDetail.ctnrCbm}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.track.ctnrKg')">{{e2eTrackDetail.ctnrKg}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.track.cartons')">{{e2eTrackDetail.cartons}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.track.ata')">{{e2eTrackDetail.ata}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.track.firmsCode')">{{e2eTrackDetail.firmsCode}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.track.drayType')">{{e2eTrackDetail.drayType}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.track.transloadType')" >{{e2eTrackDetail.transloadType}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.track.ddray')">{{e2eTrackDetail.ddray}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.track.destSite')">{{e2eTrackDetail.destSite}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.track.dWHArrival')">{{e2eTrackDetail.dWHArrival}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.track.emptyReturnAppt')">{{e2eTrackDetail.emptyReturnAppt}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.track.targetComplete')" >{{e2eTrackDetail.targetComplete}}</el-descriptions-item>
     </el-descriptions>
     <el-divider />
     <el-row style="width: 1000px; height: 200px;display: flex ">
         <el-card style="width: 460px; height: 130px; margin-right: 20px;">
           <el-row>
             <el-col :span="16">
               <span style="display: flex">
                 <el-icon :size="20" color="#409EFC"><List></List></el-icon>
                 <label style="margin-left: 6px;margin-top: 3px;font-family: Tahoma,serif ;font-size: 13px;color: #409EFC">Document 001</label>
               </span>
             </el-col>
             <el-col :span="8">
               <span>
                 <el-button style="width: 100px;margin-top: 5px;margin-right: 2px">View</el-button>
                 <el-button style="width: 100px;margin-top: 12px;margin-right: 12px">Download</el-button>
               </span>
             </el-col>
           </el-row>
        </el-card>
         <el-card style="width: 460px; height: 130px;">
           <span style="display: flex">
             <el-icon :size="20" color="#409EFC"><List></List></el-icon>
             <label style="margin-left: 6px;margin-top: 3px;font-family: 'Segoe UI',ui-serif;font-size: 13px;color: #409EFC">Document 002</label>
           </span>
           <span style="display: flex;margin-top: 30px">
             <el-button type="primary" plain style="font-family: 'Segoe UI',ui-serif;font-size: 13px" size="small">View</el-button>
             <el-button type="primary" plain style="font-family: 'Segoe UI',ui-serif;font-size: 13px" size="small">Download</el-button>
           </span>

         </el-card>
     </el-row>
     <el-divider />
     <el-descriptions :title="$t('msg.account.accountPayable')" column="6">
       <el-descriptions-item :label="$t('msg.account.drayage')">$ {{drayCostDetail.drayage}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.account.demurrageDays')">{{drayResult.pierPass}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.account.chassisRentDays')">{{drayResult.chassisRent}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.account.chassisRentCost')">$ {{drayCostDetail.chassisRent}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.account.yardStorageDays')">{{drayResult.overWeight}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.account.yardStorageCost')">$ {{drayResult.triAxle}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.account.terminalFee')">$ {{drayCostDetail.terminalFee}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.account.portCongest')">$ {{drayCostDetail.portCongest}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.account.prePull')">$ {{drayCostDetail.prePull}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.account.fuelSurcharge')">$ {{drayCostDetail.fuelSurcharge}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.account.bobTail')">$ {{drayCostDetail.bobTail}}</el-descriptions-item>
       <el-descriptions-item :label="$t('msg.account.total')">$ {{drayResult.allTotal}}</el-descriptions-item>
     </el-descriptions>
   </el-card>
 </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { getE2ETrackInfo, getDrayCostByRefId } from '@/api/track'
import { watchSwitchLang } from '@/utils/i18n'
import { defineProps, ref } from 'vue'
import { List } from '@element-plus/icons-vue'
import dayjs from 'dayjs'
const props = defineProps({
  refId: {
    type: String,
    required: true
  }
})
const store = useStore()
const e2eTrackDetail = ref({})
const drayCostDetail = ref({})
const drayResult = ref({})
const active = ref(0)
const todayInForm = () => {
  return dayjs(new Date()).format('YYYY-MM-DD')
}
const getTrackDetail = async () => {
  store.commit('e2etrack/setTrackingNumber', props.refId)
  e2eTrackDetail.value = await getE2ETrackInfo({
    refId: props.refId
  })
  const response = await getDrayCostByRefId({
    refId: props.refId
  })
  drayCostDetail.value = response.drayCostDetail
  drayResult.value = response.drayResult
  setActIndex()
}

getTrackDetail()

watchSwitchLang(async() => {
  const searchId = store.state.e2etrack.trackingNumber
  e2eTrackDetail.value = await getE2ETrackInfo({
    refId: searchId
  })
  const response = await getDrayCostByRefId({
    refId: props.refId
  })
  drayCostDetail.value = response.drayCostDetail
  drayResult.value = response.drayResult
  setActIndex()
})
function setActIndex () {
  if (e2eTrackDetail.value.swER != null && e2eTrackDetail.value.swER < todayInForm()) {
    active.value = 4
  } else if (e2eTrackDetail.value.dWHArrival != null && e2eTrackDetail.value.dWHArrival < todayInForm()) {
    active.value = 3
  } else if (e2eTrackDetail.value.fullGateOut != null && e2eTrackDetail.value.fullGateOut < todayInForm()) {
    active.value = 2
  } else if (e2eTrackDetail.value.discharge != null && e2eTrackDetail.value.discharge < todayInForm()) {
    active.value = 1
  } else {
    active.value = 0
  }
}
</script>

<style scoped>

</style>
