import request from '@/utils/request'

export const getTerminalInfoList = data => {
  return request({
    url: '/track/terminalInfo',
    params: data
  })
}
export const getCtnrPortSum = () => {
  return request({
    url: '/getCtnrPortSumList'
  })
}
export const getE2ETrackList = data => {
  return request({
    url: '/e2e/getE2ETrackList',
    params: data
  })
}
export const getTrackByPods = data => {
  return request({
    url: '/e2e/getTrackByPods',
    method: 'POST',
    data
  })
}
export const getE2ETrackByFA = data => {
  return request({
    url: '/e2e/getE2ETrackByFA',
    params: data
  })
}
export const getE2ETrackInfo = data => {
  return request({
    url: '/e2e/getE2ETrackByRefId',
    params: data
  })
}
export const getDrayCostByRefId = data => {
  return request({
    url: '/e2e/getDrayCostByRefId',
    params: data
  })
}
export const getTrackByDateRange = data => {
  return request({
    url: '/e2e/getTrackByDateRange',
    params: data
  })
}
export const getMonthlyCtnr = () => {
  return request({
    url: '/e2e/getMonthlyCtnr'
  })
}
export const getPodCtnr = () => {
  return request({
    url: '/e2e/getPodCtnrNum'
  })
}
export const trackSort = data => {
  return request({
    url: '/track/sort',
    method: 'POST',
    data
  })
}

export const trackDetail = rateId => {
  return request({
    url: `/rate/${rateId}`
  })
}
